// --------------------------------------------------
// REACT
// --------------------------------------------------
import React from 'react';

// --------------------------------------------------
// HELPERS
// --------------------------------------------------
import { graphql } from 'gatsby';

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------
import Layout from '../components/layout';
import SEO from '../components/seo';
import ImageBlock from '../components/project-image/project-image';


const BlogIndex = ( { data, location } ) => {
	const siteTitle = data.site.siteMetadata.title;
	const imageBlocks = data.allIllustrationJson.nodes;

	return (
		<Layout location={location} title={siteTitle} projectTitle="Illustration" projectTitleInView={false} colophon={data.site.siteMetadata.colophon}>
			<SEO
				title="Illustration"
				description="Scott Rankin's illustrations"
			/>
			<article itemScope itemType="https://schema.org/CreativeWork">
				<meta itemProp="name" content="Illustration" />
				<div itemProp="author" itemScope itemType="https://schema.org/Person">
					<meta itemProp="name" content={data.site.siteMetadata.author}/>
					<meta itemProp="url" content={data.site.siteMetadata.siteUrl}/>
				</div>
				<main>
					{imageBlocks && ( imageBlocks.map( ( block, index ) => (
						<ImageBlock block={block} key={index} />
					) ) )}
				</main>
			</article>
		</Layout>
	);
};

export default BlogIndex;

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
				author
				siteUrl
				colophon {
					heading
					content
				}
			}
		}
		allIllustrationJson {
			nodes {
				modifier
				images {
					alt
					file {
						...projectImage
					}
				}
				caption
			}
		}
	}
`;
